const firebaseconfig = {
  apiKey: "AIzaSyD3EdRMMt7NU5HAqcW8xpjCR7eweIaHhwQ",
  authDomain: "compeat-client.firebaseapp.com",
  databaseURL: "https://compeat-client.firebaseio.com",
  projectId: "compeat-client",
  storageBucket: "compeat-client.appspot.com",
  messagingSenderId: "79796211126",
  appId: "1:79796211126:web:9111c933dfea47878f0517",
  measurementId: "G-RNQPVQ39FT",
  vapidKey: "BH9exCB57jnbHzixWLzs0JNxGEEi0tfLdEuVBoss5T3ZJOgEe0WWbi_V85k_ZjyntWkKUJpYYFZB0CdzJcNBBC8",
  messagingSenderId: "79796211126"
};

export default firebaseconfig;