import React from 'react';
import { UpdateMessageInput } from '../API';
import Moment from 'react-moment';

import './ChatBubble.css';

interface ChatBubbleProps {
  message: UpdateMessageInput;
  right: Boolean;
}

const ChatBubble = (props: ChatBubbleProps) => {
  const { message, right } = props;
  return (
    <div className={"chat-bubble-row " + (right ? 'right' : 'left')}>
      <div className="chat-bubble-col">
        <div className="message">
          {message.content}
        </div>
        <div className="time">
          <Moment fromNow>{ message.createdAt ? message.createdAt : "" }</Moment>
        </div>
      </div>
    </div>
  );
}

export default ChatBubble;