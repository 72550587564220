import React from 'react';
import { IonRouterOutlet, IonSplitPane } from '@ionic/react';

import { home, peopleCircle } from 'ionicons/icons';
import { Route, Redirect } from 'react-router';
import { IonReactRouter } from '@ionic/react-router';
import Menu from '../components/Menu';
import Chat from '../pages/Chat';

import './DashboardLayout.css';

const DashboardLayout: React.FC<{isLoggedOut: Boolean}> = ({isLoggedOut}) => {
  const appPages = [
    {
      title: 'Home',
      url: '/dashboard',
      icon: home
    },
    {
      title: 'Clients',
      url: '/dashboard/clients',
      icon: peopleCircle
    },
    // {
    //   title: 'Notifications',
    //   url: '/dashboard/clients',
    //   icon: notifications
    // },
    // {
    //   title: 'Settings',
    //   url: '/dashboard/clients',
    //   icon: settings
    // }
  ];


  if (isLoggedOut) {
    return (<Redirect to="/" />);
  } else {
    return (
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />

          <IonRouterOutlet id="main">
            <Route path="/dashboard/chat" component={Chat} exact={true} />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    );
  }

  
};

export default DashboardLayout;