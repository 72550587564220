import React from 'react';
import { IonToolbar, IonMenu, IonHeader, IonTitle, IonContent, IonList, IonItem, IonButton, IonMenuButton, IonIcon, IonLabel, IonImg } from '@ionic/react';
import { chatbubbleOutline, logOutOutline } from 'ionicons/icons';
import * as firebase from 'firebase';

import './Menu.css';

const Menu: React.FC = () => {
  const signOut: any = () => {
    console.log('will signout');
    firebase.auth().signOut();
  }

  return (
    <IonMenu contentId="menu" type="overlay">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonImg className="menu-logo" src="/assets/images/compeat-logo.png" slot="start" />
        </IonToolbar>
      </IonHeader>
      <IonContent id="menu" color="primary" className="ion-padding-top">
        <IonList lines="none">
          <IonItem href="/dashboard/chat" color="primary"> 
            <IonIcon icon={ chatbubbleOutline } /> 
            <IonLabel className="ion-padding-start">Chat</IonLabel>
          </IonItem>
          <IonItem onClick={ () => signOut() } href="/" color="primary">
            <IonIcon icon={logOutOutline} />
            <IonLabel className="ion-padding-start">Log out</IonLabel>
          </IonItem>
          
        </IonList>
      </IonContent>
    </IonMenu>
  );
}



export default Menu;