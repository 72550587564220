import React, { useState, useEffect } from 'react';

import awsconfig from './aws-exports';
import firebaseconfig from './firebase-config';

import { Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Auth from './pages/Home';
import Welcome from './pages/Welcome';
import { User } from 'firebase';
import DashboardLayout from './layouts/DashboardLayout';
import Amplify from 'aws-amplify';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, ApolloLink, concat } from '@apollo/client';
import * as firebase from 'firebase';

import "firebase/messaging";

// import { Plugins } from '@capacitor/core';
// const { SplashScreen } = Plugins;

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { SplashScreen } from '@capacitor/core';

firebase.initializeApp(firebaseconfig);
// const messaging = firebase.messaging();

const api = {
  API: {
    graphql_headers: async () => ({
      Authorization: localStorage.getItem('auth_token')
    })
  }
}


Amplify.configure({...awsconfig, ...api});

const httpLink = createHttpLink({
  uri: awsconfig.aws_appsync_graphqlEndpoint,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('auth_token');
  operation.setContext({
    headers: {
      authorization: token
    }
  });

  return forward(operation);
});

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink)
});

export const AuthContext = React.createContext<User|null>(null);  

const App: React.FC = () => {
  
  const [authUser, setAuthUser] = useState<User|null>(null);
  
  useEffect(() => {
    SplashScreen.hide();

    
    
    firebase.auth().onAuthStateChanged(setAuthUser);

    firebase.auth().onIdTokenChanged((result) => {
      console.log("App : onIdTokenChanged");
      console.log(result);
      // localStorage.setItem('auth_token', result.stsTokenManager.accessToken);
    });

  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <AuthContext.Provider value={authUser}>
        <IonApp>
          <IonReactRouter>
            <IonRouterOutlet>

              <Route path="/" component={Welcome} exact={true} />
              <Route path="/session" component={Auth} exact={true} />

              <Route path="/dashboard" component={DashboardLayout} />
            </IonRouterOutlet>
          </IonReactRouter>
        </IonApp>
        </AuthContext.Provider>
    </ApolloProvider>
    
  );
}

export default App;
