import React, { useState } from 'react';
import { IonToolbar, IonButtons, IonMenuButton, IonButton, IonIcon, IonHeader, IonAvatar } from '@ionic/react';

import './NavBar.css';
import { CreateAthleteInput } from '../API';
import { settingsOutline } from 'ionicons/icons';

interface NavBarProps { }

const NavBar: React.FC<NavBarProps> = (props) => {
  const [user] = useState<CreateAthleteInput | null>(null);
  
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        {/* <IonButtons slot="secondary">
          <IonButton>
            <IonIcon icon={settingsOutline} />
          </IonButton>
        </IonButtons> */}
        {props.children}
      </IonToolbar>
    </IonHeader>
  );
}

export default NavBar;