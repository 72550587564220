import React from 'react';
import { IonButton, IonIcon, IonPage, IonSlides, IonContent, IonSlide, IonImg } from '@ionic/react';
import { arrowForwardOutline } from 'ionicons/icons';
import './Welcome.css';

const Welcome: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen class="ion-padding" scroll-y="false">
        <IonSlides>

          <IonSlide>
            <div className="slide">
              <IonImg src="/assets/images/slide_1.png"/>
              <h2>Compeat Nutrition</h2>
              <p><b>Chat</b> is a practical preview of the ionic framework in action, and a demonstration of proper code use.</p>
              <IonButton fill="clear" href="/session">Continue <IonIcon slot="end" icon={arrowForwardOutline}></IonIcon></IonButton>
            </div>
          </IonSlide>

          <IonSlide>
            <IonImg src="/assets/images/slide_2.png"/>
            <h2>What is Ionic?</h2>
            <p><b>Ionic Framework</b> is an open source SDK that enables developers to build high quality mobile apps with web technologies like HTML, CSS, and JavaScript.</p>
          </IonSlide>

          <IonSlide>
            <IonImg src="/assets/images/slide-3.png"/>
            <h2>What is Ionic Appflow?</h2>
            <p><b>Ionic Appflow</b> is a powerful set of services and features built on top of Ionic Framework that brings a totally new level of app development agility to mobile dev teams.</p>
          </IonSlide>

          <IonSlide>
            <IonImg src="/assets/images/slide_4.jpg"/>
            <h2>Ready to Play?</h2>
            <IonButton fill="clear" href="/session">Continue <IonIcon slot="end" icon={arrowForwardOutline}></IonIcon></IonButton>
          </IonSlide>

        </IonSlides>
      </IonContent>
    </IonPage>
  );
}

export default Welcome;