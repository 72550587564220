import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonImg, IonText, IonRouterLink, IonButton } from '@ionic/react';
import React, { useEffect } from 'react';
import * as firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { User } from 'firebase/app'
import { cfaSignIn } from 'capacitor-firebase-auth';
 
import './Home.css';

const Home: React.FC = () => {

  const uiconfig = {
    signInFlow: 'redirect',
    signInSuccessUrl: '/dashboard/chat',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
        emailLinkSignIn: function() {
          return {
            url: 'https://client.compeatnutrition.com/completeSignIn?token=123456',
            dynamicLinkDomain: 'compeatnutrition.page.link',
            handleCodeInApp: true,
            iOS: {
              bundleId: 'com.compeatnutrition.client'
            },
            android: {
              packageName: 'com.compeatnutrition.client',
              installApp: true,
              minimumVersion: '12'
            }
          };
        }
      }
    ],
    tosUrl: 'https://www.compeatnutrition.com/terms',
    privacyPolicyUrl: 'https://www.compeatnutrition.com/privacy',
    callbacks: {
      signInSuccessWithAuthResult: (authResult: any, redirectUrl: any) => {
        console.log(authResult);
        return true;
      },
    }
  };

//   personas
// tasks
// wireframes

  return (
    <IonPage className="home">
        <IonContent>
          <IonGrid>
            <IonRow className="ion-justify-content-center ion-align-items-center">
              <IonCol size-xs="12" size-sm="8" size-lg="6" size-xl="4">
                <IonCard color="primary" className="ion-text-center">
                  <IonImg src="/assets/images/logo.png" />
                  <IonCardHeader>
                    <IonCardTitle>Log in with your social account</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <StyledFirebaseAuth uiConfig={uiconfig} firebaseAuth={firebase.auth()} />
                    <IonText>
                      We won't post to any of your accounts without asking  
                    </IonText>
                  </IonCardContent>
                  <IonCardContent className="card-footer">
                    <IonText color="secondary">
                      Don't have an account? <IonRouterLink href="/" color="secondary">Sign Up</IonRouterLink>
                    </IonText>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
          
        </IonContent>
      </IonPage>
  );
};

export default Home;
