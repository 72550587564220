import React, { useEffect, createRef } from 'react';
import { IonPage, IonContent, IonTitle, IonFooter } from '@ionic/react';
import NavBar from '../components/NavBar';
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { UpdateMessageInput } from '../API';
import { isNull } from 'util';
import * as firebase from 'firebase';

import './Chat.css';
import ChatBubble from '../components/ChatBubble';
import ChatAddMessage from '../components/ChatAddMessage';

import { Plugins } from '@capacitor/core';
import { FCM } from "capacitor-fcm";

const fcm = new FCM();
const { PushNotifications } = Plugins;


// import { getAthlete, getConversation } from '../graphql/queries';

// const onCreateMessage = /* GraphQL */ gql(`
// subscription OnCreateMessage($messageConversationId: ID!) {
//   onCreateMessage(messageConversationId: $messageConversationId) {
//     id
//     content
//     createdAt
//     authorId
//     isSent
//     messageConversationId
//   }
// }
// `);

// const onUpdateConversation = /* GraphQL */ gql(`
// subscription OnUpdateConversation {
//   onUpdateConversation {
//     id
//     members
//     messages {
//       items {
//         id
//         content
//         createdAt
//         authorId
//         isSent
//         messageConversationId
//       }
//       nextToken
//     }
//   }
// }
// `);

// const onMessageCreated = /* GraphQL */ gql(`
//   subscription OnMessageCreated($messageConversationId: ID!) {
//     onMessageCreated(messageConversationId: $messageConversationId) {
//       id
//       content
//       createdAt
//       authorId
//       isSent
//       messageConversationId
//     }
//   }
// `);

// const listMessages = /* GraphQL */ gql(`
//   query ListMessages(
//     $filter: ModelMessageFilterInput
//     $limit: Int
//     $nextToken: String
//   ) {
//     listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
//       items {
//         id
//         content
//         createdAt
//         authorId
//         isSent
//         messageConversationId
//       }
//       nextToken
//     }
//   }
// `);

const MessagesByConversation = /* GraphQL */ gql(`
  query MessageByMessageConversationId(
  $messageConversationId: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  messageByMessageConversationId(
    messageConversationId: $messageConversationId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      content
      createdAt
      authorId
      isSent
    }
    nextToken
  }
}`)

const getAthlete = /* GraphQL */ `
  query GetAthlete($id: ID!) {
    getAthlete(id: $id) {
      id
      email
      name
      dateOfBirth
      membership
      phone
      status
      specialist {
        id
        firstName
        lastName
      }
      goals {
        items {
          description
        }
      }
      events {
        items {
          name
        }
      }
      conversations {
        items {
          id
          conversationID
          memberID
          conversation {
            id
            members
          }
        }
      }
    }
  }
`;

// const getConversation = /* GraphQL */ gql(`
// query GetConversation($id: ID!) {
//   getConversation(id: $id) {
//     id
//     members
//     messages {
//       items {
//         id
//         content
//         createdAt
//         authorId
//         isSent
//       }
//       nextToken
//     }
//   }
// }
// `);

const CREATE_DEVICE = /* GraphQL */ gql(`
mutation CreateDevice(
  $input: CreateDeviceInput!
  $condition: ModelDeviceConditionInput
) {
  createDevice(input: $input, condition: $condition) {
    id
    token
    clientDeviceId
  }
}
`);

const Chat: React.FC = () => {
  const conversationContent = createRef<HTMLIonContentElement>();
  const [createDevice] = useMutation(CREATE_DEVICE);

  useEffect(() => {
    conversationContent.current?.scrollToBottom();
  });
  
  PushNotifications.register().catch(err => console.log(JSON.stringify(err)));;

  fcm.getToken().then(r => {
    console.log(`Token ${r.token}`);
    createDevice({ variables: { id: r.token, token: r.token, clientDeviceId: localStorage.getItem('currentUserEmail') } });
  }).catch(err => console.log(err));
  
  // firebase.messaging().onMessage((message) => {
  //   console.log(message);
  // })


  // PushNotifications.addListener('registration', 
  //   (token: PushNotificationToken) => {
  //     alert('Push registration success, token: ' + token.value);
  //   }
  // );

  // PushNotifications.addListener('registrationError', 
  //   (error: any) => {
  //     alert('Error on registration: ' + JSON.stringify(error));
  //   }
  // );

  // PushNotifications.addListener('pushNotificationReceived', 
  //   (notification: PushNotification) => {
  //     alert('Push received: ' + JSON.stringify(notification));
  //   }
  // );

  // PushNotifications.addListener('pushNotificationActionPerformed', 
  //   (notification: PushNotificationActionPerformed) => {
  //     alert('Push action performed: ' + JSON.stringify(notification));
  //   }
  // );
  
  
  const { data, loading, error } = useQuery(gql(getAthlete), { variables: { id: localStorage.getItem('currentUserEmail') } });
  // useQuery(gql(listConversations));
  if (loading || error || !data) return <IonPage id="mainContent"></IonPage>;

  const conversationId = data.getAthlete.conversations.items.slice().shift().conversationID;
  
  return (
    <IonPage id="mainContent">
      <NavBar><IonTitle>Chat</IonTitle></NavBar>
      <IonContent className="chat ion-align-items-stretch ion-padding" ref={conversationContent}>
        
        <Conversation messageConversationId={conversationId} limit={10} sortDirection="DESC" />
      </IonContent>
      <IonFooter>
        <ChatAddMessage conversationId={conversationId} />
      </IonFooter>
    </IonPage>
  );
}

export default Chat;



const Conversation = (props: any) => {
  const { data, loading, error } = useQuery(MessagesByConversation, { variables: props, pollInterval: 5000 });
  
  if (loading) return <div>Loading</div>;
  if (error) {
    // this will refresh the token if it's expired
    firebase.auth().currentUser?.getIdToken();
  };
  if (!data) return <p>Not found</p>;

  return data.messageByMessageConversationId?.items?.slice().reverse().map((message: UpdateMessageInput | null) => {
    if (isNull(message)) {
      return null;
    } else {
      let isUser = localStorage.getItem('currentUserEmail') === message?.authorId;
  
      return (
        <ChatBubble key={message.id} message={message} right={isUser} />
      ); 
    }
  });
}

