import React, { useState } from "react";
import { IonItem, IonTextarea, IonButton, IonIcon } from "@ionic/react";
import { paperPlaneOutline } from "ionicons/icons";

import "./ChatAddMessage.css";
import { useMutation, gql } from "@apollo/client";

interface AddMessageProps {
  conversationId: string;
}

const ChatAddMessage = (addMessageProps: AddMessageProps) => {
  const createMessage = /* GraphQL */ gql(`
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      content
      createdAt
      authorId
      isSent
      messageConversationId
    }
  }
  `);
  const [addMessage, { data }] = useMutation(createMessage);
  const [content, setContent] = useState('');
  
  const handleSubmit = (event: any) => {
    event.preventDefault();
    const data = new FormData(event.target);
    let message = data.get('message');
    // let input = {content: message, authorId: addMessageProps.user, messageConversationId: addMessageProps.conversationId};
    let input = {content: message, authorId: localStorage.getItem('currentUserEmail'), messageConversationId: addMessageProps.conversationId};
    addMessage({variables: {input: input}});
    setContent('sending...');
  }

  return (
    <div className="add-message-form">
      <form onSubmit={handleSubmit}>
        <IonItem>
          <IonTextarea name="message" placeholder="Type your message..." value={content} />
          <button slot="end" type="submit"><IonIcon slot="icon-only" icon={paperPlaneOutline} /></button>
        </IonItem>
        
      </form>
    </div>
  );
}

export default ChatAddMessage;